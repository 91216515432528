let isInitialized = false;
export function initialize() {
  if (isInitialized) return;

  isInitialized = true;

  document.addEventListener("click", (event) => {
    const clickTarget = event.target as HTMLElement;
    if (clickTarget.matches("[data-service-card] [data-readmore]")) {
      clickTarget
        .closest("[data-service-card]")
        ?.querySelector("[data-more-info]")
        ?.classList.remove("-translate-y-full");
    }

    if (clickTarget.matches("[data-service-card] [data-close]")) {
      clickTarget
        .closest("[data-more-info]")
        ?.classList.add("-translate-y-full");
    }
  });
}
